import React, { FC, useEffect, useState } from 'react';
import styles from '@component/Marking/contentMarking.module.scss';
import { ArrowLeftIcon, Button } from '@rubin-dev/goblin';
import { FormMarking, MarkingForm } from '@component/Marking/FormMarking';
import { useTranslation } from 'react-i18next';
import { RouterName } from '../../router';
import {
  Network,
  useExplorerFindMentionsForAddressLazyQuery,
  useExplorerMarkupCreateMutation,
} from '@apolloGenerated';
import { detectNetworkByAddress } from '@aml/validation';
import { useNavigate } from 'react-justanother-router';
import { useModal } from '@hooks/useModal';
import { getNetworkEnum } from '@helpers/address';

interface Props {
  address: string;
}

export const MarkingCreateComponent: FC<Props> = ({ address }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigate();

  const [currentAddress, setCurrentAddress] = useState<string | null>(null);
  const [fetchMentions] = useExplorerFindMentionsForAddressLazyQuery();

  const openDialog = useModal('dialogSuccess');

  const [mutate] = useExplorerMarkupCreateMutation();

  const onSuccess = () => {
    fetchMentions({
      variables: {
        network: getNetworkEnum(String(detectNetworkByAddress(address)?.toUpperCase())),
        address,
      },
      fetchPolicy: 'network-only',
    });
  };

  const onSubmit = async (values: MarkingForm) => {
    setCurrentAddress(values?.address || '');
    await mutate({
      variables: {
        // @ts-ignore
        req: {
          ...values,
          network: detectNetworkByAddress(String(address))?.toUpperCase() as Network,
          ...(values?.otherAddresses
            ? {
                otherAddresses: values?.otherAddresses.map((item) => {
                  return {
                    network: detectNetworkByAddress(String(item))?.toUpperCase(),
                    address: item,
                  };
                }),
              }
            : {}),
          level: Number(values.level),
          mentionSourceID: 38,
        },
      },
    });

    openDialog({ onSuccess });
  };

  useEffect(() => {
    if (currentAddress) {
      navigate(RouterName.Marking, {}, { query: currentAddress });
    }
  }, [currentAddress]);

  const onPrevPage = () => {
    navigate(RouterName.Marking, {}, { query: address });
  };

  return (
    <section className={styles.section}>
      <Button
        variant={'outlined'}
        icon
        prependIcon={ArrowLeftIcon}
        onClick={onPrevPage}
      />
      <FormMarking title={t('marking.add')} formOnSubmit={onSubmit} address={address} />
    </section>
  );
};
