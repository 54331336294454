import { AddressTransactionStats, Cluster, Maybe, NodeCoords } from '@apolloGenerated';
import { Meta } from '../Meta/Meta';
import { NodeItem, TableType } from '@graph/types';
import { AreaType } from '@rubin-dev/octavius';
import { getSplicedString } from '@utils/string';
import {
  ACTIVE_COLOR,
  ACTIVE_WIDTH,
  BALANCE_COLORS,
  CATEGORY_STROKE_WIDTH,
  COLORS,
  COUNT_TR_SIZE,
  MENTION_STROKE_COLOR,
  MENTION_STROKE_OFFSET,
  MENTION_STROKE_WIDTH,
  SIZES,
} from '../../const/address.const';
import { AddressColor, AddressSize } from '../../types/address';
import { NodeList } from './NodeList';
import { RiskCategories } from '../Risk/RiskCategories';
import { GraphNodeLabel } from '@graph/modules';
import { Annotation } from '@graph/models/Annotation/Annotation';
import { v4 as uuidv4 } from 'uuid';

export type AnnotationList = Record<string, Annotation>;
export type NodeRiskArg = { risk?: Maybe<number>; categoryNumber?: Maybe<number> };
export type NodeStatsParams = {
  balance: string;
  counts: AddressTransactionStats;
  risk: NodeRiskArg;
  cluster?: Maybe<Cluster>;
  coords?: NodeCoords;
  annotation?: Maybe<AnnotationList>;
};
export class Node {
  hash: string;
  total: number;
  totalIn: number;
  totalOut: number;
  balance: number;
  hasMention: boolean;
  category: string = 'empty';
  risk: Maybe<number> = null;
  tableHash: string;
  reportedCategory?: string;
  reportedColor?: string;
  cluster?: Cluster;
  type: TableType.Cluster | TableType.Address;
  annotation: AnnotationList;
  coords?: NodeCoords;
  node?: NodeItem;
  constructor(
    hash: Node['hash'],
    tableHash: Node['tableHash'],
    { cluster, counts, risk, balance, coords, annotation }: NodeStatsParams,
  ) {
    const network = Meta.network;
    const riskCategories = RiskCategories.get(network);

    this.hash = hash;
    this.total = +counts.total;
    this.totalIn = +counts.received;
    this.totalOut = +counts.sent;
    this.balance = +balance || 0;

    if (!isNaN(Number(risk?.risk)) && risk?.risk !== null) this.risk = Number(risk.risk);

    if (riskCategories && risk.categoryNumber) {
      this.reportedCategory = riskCategories[risk.categoryNumber]?.name;
      this.reportedColor = riskCategories[risk.categoryNumber]?.color;
    }
    this.annotation = annotation || {};

    if (coords) this.coords = coords;
    this.hasMention = !!this.reportedCategory;
    this.tableHash = tableHash;
    if (cluster) this.cluster = cluster;

    this.type = cluster ? TableType.Cluster : TableType.Address;
  }

  private getNodeLabel(): JSX.Element {
    return (
      <GraphNodeLabel
        hash={this.hash}
        label={getSplicedString(this.hash)}
        risk={this.risk}
        category={this.reportedCategory}
        active={this.node?.active}
        owner={this.cluster?.owner}
        balance={this.balance}
        total={this.total}
        removed={NodeList.getCountAddresses() > 1}
        network={Meta.network}
      />
    );
  }

  private getNodeSize(): number {
    if (this.total <= COUNT_TR_SIZE[AddressSize.SMALL]) return SIZES[AddressSize.SMALL];
    if (this.total <= COUNT_TR_SIZE[AddressSize.MEDIUM]) return SIZES[AddressSize.MEDIUM];
    return SIZES[AddressSize.LARGE];
  }
  private getNodeFill(): string {
    if (this.balance <= BALANCE_COLORS[AddressColor.SMALL][Meta.network])
      return COLORS[AddressColor.SMALL];
    if (this.balance <= BALANCE_COLORS[AddressColor.MEDIUM][Meta.network])
      return COLORS[AddressColor.MEDIUM];
    return COLORS[AddressColor.LARGE];
  }

  private getNodeStrokeColor(): string {
    return this.reportedColor || '#A8B1BD';
  }

  private getMentionArea(): AreaType {
    let size = this.getNodeSize() + CATEGORY_STROKE_WIDTH + MENTION_STROKE_OFFSET;
    if (this.node?.active) size -= ACTIVE_WIDTH;
    return {
      size,
      fill: 'transparent',
      strokeWidth: MENTION_STROKE_WIDTH,
      stroke: MENTION_STROKE_COLOR,
    };
  }

  setNode(): void {
    this.node = {
      uuid: this.hash,
      label: {
        location: 'bottom',
        render: this.getNodeLabel(),
      },
      size: this.getNodeSize(),
      area: [
        {
          size: CATEGORY_STROKE_WIDTH + this.getNodeSize(),
          fill: this.getNodeStrokeColor(),
          stroke: '',
          strokeWidth: 0,
        },
        {
          size: this.getNodeSize(),
          fill: this.getNodeFill(),
          stroke: '',
          strokeWidth: 0,
        },
      ],
      fx: this.coords?.x,
      fy: this.coords?.y,
      type: this.type,
    };
    if (this.hasMention) this.node.area.unshift(this.getMentionArea());
  }

  setCoords(x: number, y: number): void {
    this.coords = {
      x,
      y,
    };
  }

  createAnnotation(text?: string): Annotation {
    const hash = uuidv4();
    this.annotation[hash] = new Annotation(hash, this.hash, TableType.Address, { text });
    return this.annotation[hash];
  }

  activateNode(): void {
    if (!this.node) return;
    const size = this.node.size + CATEGORY_STROKE_WIDTH + ACTIVE_WIDTH;
    this.node.active = true;
    this.node.area.unshift({
      size,
      fill: ACTIVE_COLOR,
      stroke: '',
      strokeWidth: 0,
    });
  }

  rerenderNode() {
    if (!this.node?.label) return;
    this.node = {
      ...this.node,
      label: {
        ...this.node.label,
        render: this.getNodeLabel(),
      },
    };
  }
}
