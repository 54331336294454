import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogFooter,
  Informer,
  Typography,
  WithCloseModal,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { Network } from '@apolloGenerated';
import sdk from '@aml/sdk';
import { history, router, RouterName } from '../../router';

export type DialogSuccessProps = {
  onSuccess?(): void;
};
declare global {
  interface ModalProps {
    dialogSuccess: DialogSuccessProps;
  }
}
export const DialogSuccess: FC<WithCloseModal<DialogSuccessProps>> = ({
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const onClick = () => {
    onSuccess && onSuccess();
    onClose();
  };

  return (
    <Dialog
      width={500}
      footerSlot={
        <Button size="large" color="secondary" fullWidth onClick={onClick}>
          {t('buttons.ok')}
        </Button>
      }
    >
      <Informer
        type="check"
        title={t('titles.markupQueued')}
        isSuccess
        color={'#fff'}
        subtitle={t('titles.markupAdded')}
      />
    </Dialog>
  );
};
