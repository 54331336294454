import React, { FC, useMemo } from 'react';
import {
  Network,
  useExplorerAddressActivityQuery,
  useExplorerAddressQuery,
  useExplorerAddressTransactionStatsQuery,
  useExplorerClusterForAddressQuery,
  useExplorerFindAddressByHashQuery,
  useExplorerRiskQuery,
  useMeQuery,
} from '@apolloGenerated';
import { Link } from 'react-justanother-router';
import { Typography } from '@rubin-dev/goblin';
import { ListItem, Blur } from '@shared/ui';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { RiskDot } from '@component/NewRisk/ui/RiskDot/RiskDot';
import { RouterName } from '../../../../router';
import { amount, format } from '@aml/amount';
import { BalanceTooltip, TransactionsTooltip } from '@component/Address';
import { BigNumber } from 'bignumber.js';
import { useActivityPeriod } from '@component/Address/libs/hooks/useActivityPeriod';
import { getRiskColor } from '@helpers/address';

export type AddressMainInfoProps = {
  address: string;
  network: Network;
  hideRisk?: boolean;
  hideCluster?: boolean;
  hideCategory?: boolean;
  hideOwner?: boolean;
  hideTransactions?: boolean;
  hideBalance?: boolean;
  hideActivity?: boolean;
};
export const AddressMainInfo: FC<AddressMainInfoProps> = ({
  address,
  network,
  hideRisk,
  hideCluster,
  hideCategory,
  hideOwner,
  hideTransactions,
  hideBalance,
  hideActivity,
}) => {
  const { t } = useTranslation();
  const { data: user } = useMeQuery({ fetchPolicy: 'network-only' });

  const variables = {
    network,
    address,
  };
  // RISK
  const { data: riskData } = useExplorerRiskQuery({
    variables,
    skip: hideRisk,
  });
  const risk = Number(riskData?.explorerRisk?.risk?.risk);
  const reported = riskData?.explorerRisk.risk?.reported?.name || undefined;
  const categoryName = useMemo(() => {
    const findCategory = riskData?.explorerAddressCategories?.categories?.find(
      (category) => category.number === riskData.explorerRisk.risk?.reported?.number,
    );
    return findCategory ? findCategory.name : '';
  }, [riskData]);

  // OWNER
  const { data: addressDetails } = useExplorerAddressQuery({
    variables,
    skip: hideOwner,
  });
  const owner = addressDetails?.explorerAddress?.owner;

  // CLUSTER
  const { data: clusterData } = useExplorerClusterForAddressQuery({
    variables,
    skip: hideCluster,
  });
  const cluster = clusterData?.explorerClusterForAddress?.cluster;

  // TRANSACTIONS
  const { data: dataTransactions } = useExplorerAddressTransactionStatsQuery({
    variables,
    skip: hideTransactions,
  });
  const stats = dataTransactions?.explorerAddressTransactionStats?.stats;
  const total = stats?.total || 0;
  const totalSent = stats?.sent || 0;
  const totalReceived = stats?.received || 0;

  // BALANCE
  const { data: dataBalance } = useExplorerFindAddressByHashQuery({
    variables,
    skip: hideBalance,
  });
  const node = dataBalance?.explorerFindAddressByHash?.node;
  const formatBalance = (val?: string) =>
    Number(val) > 0 ? amount(BigNumber(val || 0).toString(10), network, false) : 0;
  const balance = formatBalance(node?.total);
  const balanceIn = formatBalance(node?.totalIn);
  const balanceOut = formatBalance(node?.totalOut);

  // ACTIVITY
  const { data } = useExplorerAddressActivityQuery({
    variables,
    skip: hideActivity,
  });
  const activity = data?.explorerAddressActivity?.activity;
  const getActivityPeriod = useActivityPeriod();

  const expireDate = useMemo(
    () => user?.me.billing.analyzerSubscriptionExpireDate,
    [user?.me.billing.analyzerSubscriptionExpireDate],
  );

  const setLink = (wid?: string | undefined, owner?: string | null): JSX.Element => {
    if (!wid) return <></>;
    return (
      <Link to={RouterName.ExplorerWallet} params={{ wid: String(wid), network }}>
        <Typography variant="head-16" color={'primary-1'}>
          {!owner ? 'Entity' : owner}
        </Typography>
      </Link>
    );
  };

  return (
    <div className={styles.addressInfoMain}>
      {(!!(!hideRisk && risk) || !expireDate) && (
        <ListItem
          className={styles.addressInfoMain__item}
          label={`${t('strings.riskscore')}:`}
          value={
            expireDate ? (
              <Typography className={styles.addressInfoMain__risk} variant="head-16">
                <RiskDot risk={Number(risk)} size={12} />
                {risk}%
              </Typography>
            ) : (
              <Blur to={RouterName.TariffAnalyzer}>
                <RiskDot risk={Number(risk)} size={12} />
                000%
              </Blur>
            )
          }
        />
      )}
      {cluster?.owner !== null && cluster?.id && (!hideCluster || expireDate) && (
        <ListItem
          className={styles.addressInfoMain__item}
          label={`${t('mention.cluster')}:`}
          value={
            expireDate ? (
              setLink(cluster?.id, cluster?.owner)
            ) : (
              <Blur to={RouterName.TariffAnalyzer}>
                <Typography variant="head-16" color="primary-1">
                  Cluster
                </Typography>
              </Blur>
            )
          }
        />
      )}
      {(!!(!hideCategory && categoryName) || !expireDate || !!reported) && (
        <ListItem
          className={styles.addressInfoMain__item}
          label={`${t('strings.reported')}:`}
          value={
            expireDate ? (
              <Typography variant="head-16" style={{ color: getRiskColor(risk) }}>
                {categoryName || reported}
              </Typography>
            ) : (
              <Blur to={RouterName.TariffAnalyzer}>
                <Typography variant="head-16" color="error-2">
                  Category Name
                </Typography>
              </Blur>
            )
          }
        />
      )}
      {(!!(!hideOwner && owner) || !expireDate) && (
        <ListItem
          className={styles.addressInfoMain__item}
          label={`${t('strings.owner')}:`}
          value={
            expireDate ? (
              <Typography variant="head-16" color="error-2">
                {owner}
              </Typography>
            ) : (
              <Blur to={RouterName.TariffAnalyzer}>
                <Typography variant="head-16" color="error-2">
                  Owner
                </Typography>
              </Blur>
            )
          }
        />
      )}
      {!!(!hideTransactions && dataTransactions) && (
        <ListItem
          className={styles.addressInfoMain__item}
          label={
            <>
              {`${t('strings.transactions')}:`}{' '}
              <TransactionsTooltip
                total={format(total, 0)}
                totalReceive={format(totalReceived, 0)}
                totalSent={format(totalSent, 0)}
                dense
                network={network}
              />
            </>
          }
          value={format(total, 0)}
        />
      )}
      {!!(!hideBalance && dataBalance) && (
        <ListItem
          className={styles.addressInfoMain__item}
          label={
            <>
              {`${t('strings.balance')}:`}{' '}
              <BalanceTooltip
                totalIn={`${balanceIn} ${network}`}
                totalOut={`${balanceOut} ${network}`}
                dense
                network={network}
              />
            </>
          }
          value={`${balance} ${network}`}
        />
      )}
      {!!(!hideActivity && activity) && (
        <ListItem
          className={styles.addressInfoMain__item}
          label={`${t('titles.activity')}:`}
          value={getActivityPeriod({ first: activity.first, last: activity.last })}
        />
      )}
    </div>
  );
};
