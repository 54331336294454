import { ChangeEvent, FC, useMemo, useState } from 'react';
import { PlusDenseIcon, SearchDenseIcon, TextField } from '@rubin-dev/goblin';
import { TableType } from '@graph/types';
import { EventName, useGraphStore } from '@graph/libs';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useAddressValidate, useTransactionValidate } from '@shared/libs';
import { Meta } from '@graph/models';
import { getNetworkEnum } from '@helpers/address';
import { Network } from '@apolloGenerated';
import cx from 'classnames';

export const GraphInput: FC = () => {
  const { t } = useTranslation();
  const { sendEvent } = useGraphStore();
  const network = getNetworkEnum(Meta.network || '');
  const isFullFeature = network !== Network.Btc;
  const [value, setValue] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [validateAddress, { loading: loadingAddress }] = useAddressValidate(network);
  const [validateTransaction, { loading: loadingTransaction }] =
    useTransactionValidate(network);
  const loading = loadingTransaction || loadingAddress;

  const successAddressCallback = (hash: string) => {
    return sendEvent({
      type: EventName.DATA_ADD_ADDRESS_WITH_CONNECTIONS,
      params: { address: hash },
    });
  };
  const successTransactionCallback = (hash: string) => {
    sendEvent({
      type: EventName.DATA_INIT_HASH,
      params: { type: TableType.Transaction, hash },
    });
  };
  const error = useMemo(() => {
    if (isFullFeature) {
      return hasError ? t('errors.incorrectAddressOrTr') : '';
    }
    return hasError ? t('errors.incorrectAddress') : '';
  }, [isFullFeature, hasError]);
  const handleSubmit = async () => {
    try {
      if (await validateAddress(value)) successAddressCallback(value);
      else if ((await validateTransaction(value)) && isFullFeature)
        successTransactionCallback(value);
      else throw new Error('Invalid value');
      setValue('');
    } catch {
      setHasError(true);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (hasError) setHasError(false);
    setValue(e.target.value);
  };

  return (
    <TextField
      className={styles.graphInput}
      value={value}
      error={error}
      placeholder={
        isFullFeature ? t('strings.insertAddressOrTr') : t('strings.insertonlyaddress')
      }
      onChange={handleChange}
      prependSlot={<SearchDenseIcon />}
      appendSlot={
        value ? (
          <PlusDenseIcon
            className={cx(
              styles.graphInput__submit,
              hasError && styles.graphInput__submit_error,
            )}
            onClick={() => handleSubmit()}
          />
        ) : undefined
      }
    />
  );
};
