import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { getNetworkEnum } from '@helpers/address';
import { useExplorerFindMentionsForAddressLazyQuery } from '@apolloGenerated';
import {
  Button,
  CodeIcon,
  CopyHash,
  Informer,
  TableController,
  toaster,
  Typography,
} from '@rubin-dev/goblin';
import { useTranslation } from 'react-i18next';
import { ApolloErrors, ApolloErrorService } from '@service/ApolloErrorService';
import { MentionsError, SubscribeButton, useSubscriptionStore } from '@component/Mention';
import { formatDate } from '@utils/timestamp';
import styles from '../Transactions/transactions.module.scss';
import cls from './styles/markup.module.scss';
import sdk from '@aml/sdk';
import { RiskDot } from '@component/NewRisk';
import { useModal } from '@hooks/useModal';
import i18n from 'i18next';
import { hasPermission } from '@utils/auth';
import { SUBSCRIPTION_EXPLORER_MENTIONS } from '@constants/permissions.const';
import { GraphQLError } from 'graphql/index';

type ExplorerMentionsType = {
  network: string;
  address: string;
  checkCount?: number;
  subscribeCallback: () => void;
  onClick: () => void;
};

export const Mentions: FC<ExplorerMentionsType> = ({
  network,
  address,
  subscribeCallback,
  checkCount = 1,
  onClick,
}) => {
  const headers = () => [
    { title: i18n.t('titles.date'), value: 'date', width: '17%' },
    { title: i18n.t('links.category'), value: 'category', width: '27%' },
    { title: i18n.t('titles.source'), value: 'source', width: '22%' },
    { title: i18n.t('titles.source_address'), value: 'source_address', width: '22%' },
    { title: i18n.t('titles.proofs'), value: 'proofs', width: '12%' },
  ];

  const { t } = useTranslation();
  const [uuidNotice, setUuidNotice] = useState<string | null>(null);
  const openViewDetails = useModal('modalViewDetails');

  const { isSubscribed } = useSubscriptionStore();

  const [fetchMentions, { data, error: errorMarkup, loading: isLoadingMarkup }] =
    useExplorerFindMentionsForAddressLazyQuery();

  const { data: categoryDict } = sdk.risk.BTC.queries.useRiskServiceCurrentDict();

  useLayoutEffect(() => {
    fetchMentions({
      variables: {
        network: getNetworkEnum(network),
        address,
      },
    });
  }, [address]);

  const mentions = data?.explorerFindMentionsForAddress?.edge;

  const isInsufficientBalance = ApolloErrorService.hasError(
    errorMarkup?.graphQLErrors as GraphQLError[],
    ApolloErrors.PAYMENT_REQUIRED,
  );

  const filterMentions = useMemo(() => {
    if (!mentions?.length) return [];
    return mentions.filter((item) => item?.reportId || item?.isPaid);
  }, [mentions]);

  const renderDate = (timestamp: string) => {
    return (
      <div>
        <Typography variant="body-14">{formatDate(timestamp, true)}</Typography>
        <Typography variant="body-12" color="on-secondary-2">
          {formatDate(timestamp, false)}
        </Typography>
      </div>
    );
  };

  const renderCategory = (itemNumber: number, color?: string) => {
    const findCategory = Object.values(categoryDict?.params || {})?.find(
      (item) => item.number === itemNumber,
    );

    return (
      <div className={styles['transactions__group-cell']}>
        <div className={styles['transactions__category']}>
          {color && <RiskDot risk={findCategory?.risk ? findCategory?.risk : 0.1} />}
          {findCategory?.name || ' '}
        </div>
      </div>
    );
  };

  const renderSourceAddressLink = (sourceAddress?: string) => {
    if (!sourceAddress?.length) return '';

    return (
      <div className={styles['transactions__wrapper']}>
        <CopyHash hash={sourceAddress} onClickHash={() => openAddress(sourceAddress)} />
      </div>
    );
  };

  const renderProofs = (id?: number) => {
    return (
      <Button variant={'outlined'} onClick={() => openModal(id)}>
        {t('titles.viewDetails')}
      </Button>
    );
  };

  const list = useMemo(() => {
    return (
      filterMentions.map((item) => ({
        date: renderDate(item.createdAt),
        category: renderCategory(
          item.risk?.categoryNumber || item.source.categoryNumber,
          item.risk?.color,
        ),
        source: item?.source?.name || 'MatchSystems Reported',
        source_address: renderSourceAddressLink(item.source.sourceAddress),
        proofs: renderProofs(item?.id),
      })) || []
    );
  }, [data?.explorerFindMentionsForAddress.edge, categoryDict]);

  const isEmptyData = !mentions?.length || !list.length;

  useEffect(() => {
    toaster.clear();
  }, [data]);

  useEffect(() => {
    if (isLoadingMarkup || checkCount > 1) return;

    toaster.warning({
      title: t('titles.markup'),
      contentSlot: (
        <Typography variant="body-12" color="on-surface-secondary-2">
          {t('strings.requestEnded')} <br /> {t('strings.contactAdmin')}
        </Typography>
      ),
    });

    if (isInsufficientBalance && !uuidNotice) {
      const uuid = toaster.warning({
        title: t('titles.markup'),
        contentSlot: (
          <Typography variant="body-12" color="on-surface-secondary-2">
            {`${t('strings.requestEnded')}. <br> ${t('strings.contactAdmin')}.`}
          </Typography>
        ),
        icon: CodeIcon,
        timeout: 5000,
      });

      setUuidNotice(uuid);
    }
  }, [errorMarkup, isInsufficientBalance]);

  const openAddress = (sourceAddress: string) => {
    const url = sourceAddress.match(/^https?:/) ? sourceAddress : '//' + sourceAddress;

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const openModal = (id?: number) => {
    openViewDetails({ address, details: true, id });
  };

  if (checkCount < 1) {
    return (
      <Typography variant={'head-20'}>{t('explorer.permission_subscribe')}</Typography>
    );
  }

  if (isSubscribed && isEmptyData) {
    return (
      <div className={styles['markup__not-found']}>
        <Informer
          type="check"
          title={t('markup.requestHasBeen')}
          subtitle={
            <span>
              {t('markup.waitResult')} &quot;
              <Typography
                tag={'span'}
                variant={'body-16'}
                onClick={onClick}
                color={'primary-1'}
              >
                {t('titles.requests')}
              </Typography>
              &quot;
            </span>
          }
          isSuccess
          color={'#dffcec'}
        />
      </div>
    );
  }

  if (isInsufficientBalance) {
    return <MentionsError title={t('strings.requestEnded')} />;
  }

  if ((errorMarkup || isEmptyData) && !isLoadingMarkup) {
    return (
      <div className={styles['markup__not-found']}>
        <Informer
          type="empty"
          title={t('titles.addressNotFound')}
          subtitle={t('explorer.markup_subscription')}
        />
        <SubscribeButton
          address={address}
          network={network}
          subscribeCallback={subscribeCallback}
        />
      </div>
    );
  }

  return (
    <div className={cls['markup__table']}>
      {!isLoadingMarkup && filterMentions && (
        <TableController headers={headers()} data={list} />
      )}
    </div>
  );
};
